/* You can add global styles to this file, and also import other style files */
@import '../node_modules/alertifyjs/build/css/alertify.min.css';
@import '../node_modules/alertifyjs/build/css/themes/bootstrap.min.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

label {
    font-weight: bold;
}

.table-full-width {
    width: 100%;
}